<template>
  <div class="p-1">
    <div class="flex justify-between items-center mb-2">
      <h3 class="text-2xl">Bitácoras</h3>
      <a-button :to="{ name: 'create_binnacle' }" v-if="!user.isStudent && !user.isLowAdmin" class="w-full md:w-auto">
        Crear bitácora
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>
      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'edit_binnacle', params: { id: item.id } }">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deleteActivity(item.id)">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    query: {
      limit: 10,
      search: '',
      with: 'student,courseSection.course,courseSection.section,teacher,courseSection.specialization,period',
      sort_by: 'incidence_date,desc'
    },
    timeout: null
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.binnacles.pagination,
      loading: state => state.binnacles.loading
    }),
    columns() {
      return [
        { title: 'Fecha', key: 'incidence_date', display: true },
        { title: 'Período', key: 'period', mutate: ({ key }) => `${key.year}-${key.number}`, display: true },
        { title: "Semestre", key: 'course_section', mutate: ({ key }) => `${key.course.code.slice(-1)}`, display: true },
        { title: 'Descripción', key: 'description', mutate: ({ key }, { truncate }) => truncate(key, 150), display: true },
        { title: 'Curso', key: 'course_section', mutate: ({ key }) => `${key.course.title} ${key.section.letter_code}`, display: true },
        { title: 'Estudiante', key: 'student', mutate: ({ key }) => `${key.last_name}, ${key.first_name}`, display: true },
        { title: 'Profesor', key: 'teacher', mutate: ({ key }) => `${key.last_name}, ${key.first_name}`, display: true },
        { title: 'Especialidad', key: 'course_section.specialization.title', display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', columnFilterable: false, display: !this.user.isStudent && !this.user.isLowAdmin },
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetchBinnacles: 'binnacles/index',
      deleteActivity: 'binnacles/delete'
    }),
    search(query) {
      this.fetchBinnacles({ ...this.query, ...query })
    }
  },
  mounted() {
    this.search({ page: this.$route.query.page || 1 })
  }
}
</script>
